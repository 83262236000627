import { Box, Button, Grid, styled, Typography } from "@mui/material";
import React from "react";
import ServicesComp from "../components/ServicesComp";
import Footer from "../components/Footer";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
const MyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("lg")]: {
    display: "flex",
    gap: 30,
    alignItems: "flex-start",
    flexDirection: "column",
  },
}));

const grid = [
  {
    time: "Remote - Full Time",
    Pos: "Developer",
    dev: "Jr Frontend Developer",
  },
  {
    time: "Remote - Full Time",
    Pos: "Designer",
    dev: "UI/UX and Product Designer",
  },
  {
    time: "Full Time",
    Pos: "Manager",
    dev: "Senior Office Manager",
  },
  {
    time: "Remote",
    Pos: "Developer",
    dev: "Senior Backend Developer",
  },
];
const grid2 = [
  {
    h: "Remote Working Facilities",
    log: {
      log: <AddHomeWorkIcon sx={{ color: "#175cff", fontSize: "36px" }} />,
    },
  },
  {
    h: "Flexible Working Hours",
    log: {
      log: (
        <AccessTimeFilledOutlinedIcon
          sx={{ color: "#175cff", fontSize: "36px" }}
        />
      ),
    },
  },
  {
    h: "Friendly Skilled Team",
    log: {
      log: <PeopleAltIcon sx={{ color: "#175cff", fontSize: "36px" }} />,
    },
  },
  {
    h: "Medical Insurance Facilities",
    log: {
      log: <MedicalServicesIcon sx={{ color: "#175cff", fontSize: "36px" }} />,
    },
  },
];

export default function Position() {
  return (
    <>
      {/* one */}
      <section>
        <ServicesComp head={"Career"} />
      </section>
      {/* two */}
      <section className="container">
        <Box py={{ xs: "3.75rem", md: "7.5rem" }}>
          <MyBox px={1.5} mb={5}>
            {/* left text */}
            <Box>
              <Typography
                variant="h4"
                color={"#175cff"}
                fontWeight={600}
                mb={0.75}
              >
                Our Jobs
              </Typography>
              <Typography variant="h2">
                Current Available Positions at Technoventive
              </Typography>
            </Box>
            {/* right text */}
            <Box maxWidth={"746px"}>
              <Typography variant="h6">
                Phosfluorescently disintermediate revolutionary paradigms before
                enabled interfaces. Dynamically transition skills vis-a-vis
                virtual customer service via impactful partnerships with
                technically sound paradigms with cutting-edge initiatives.
              </Typography>
            </Box>
          </MyBox>

          <Grid container spacing={3} px={1.25}>
            {grid.map((v) => (
              <Grid item xs={12} md={6}>
                <Box
                  minHeight={"267px"}
                  bgcolor="#0b163f"
                  borderRadius={"1rem"}
                  p={6}
                >
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="subtitle2"
                      color={"#d9d9d9"}
                      fontWeight={400}
                    >
                      {v.time}
                    </Typography>

                    <span
                      class="badge"
                      style={{
                        background: "hsla(0,0%,100%,.1)",
                        color: "#d9d9d9",
                        padding: "8px 16px",
                        maxHeight: "28px",
                        borderRadius: "50rem",
                        fontWeight: 400,
                      }}
                    >
                      {v.Pos}
                    </span>
                  </Box>
                  <Box>
                    <Typography
                      variant={"h4"}
                      fontWeight={600}
                      color={"#fff"}
                      mb={9}
                      mt={0.25}
                    >
                      {v.dev}
                    </Typography>
                    {/* button */}
                    <Button
                      disableRipple
                      disableFocusRipple
                      disableElevation
                      sx={{
                        color: "#d9d9d9",
                        border: "2px solid #c0c0c5",
                        padding: "10.4px 28px",
                        borderRadius: " 0.375rem",
                        fontSize: "14px",
                        ":hover": {
                          border: "2px solid #fff",
                          background: "#fff",
                          color: "#071c4d",
                          transition: "all .4s ease-in-out",
                        },
                      }}
                    >
                      Apply Now
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </section>
      {/* three */}
      <section style={{ background: "#f8f9fa" }}>
        <Box py={{ xs: "3.75rem", md: "7.5rem" }}>
          <Box textAlign={"center"} mb={6.25}>
            <Typography
              variant="h4"
              color={"#175cff"}
              fontWeight={600}
              mb={0.75}
            >
              Why Join Us
            </Typography>
            <Typography variant="h2" mb={"0.625rem"}>
              Great Working Environment
            </Typography>
            <Typography
              variant="h6"
              maxWidth={"636px"}
              mx={"auto"}
              lineHeight={1.5}
              mb={2}
              px={3}
            >
              Revolutionary paradigms before enabled interfaces dynamically
              transition technically sound paradigms with cutting-edge
              initiatives.
            </Typography>
          </Box>
          <div className="container">
            <Grid container spacing={3} px={1.25}>
              {grid2.map((v) => (
                <Grid item xs={12} md={6}>
                  <Box
                    minHeight={"190px"}
                    bgcolor="#fff"
                    borderRadius={"1rem"}
                    p={6}
                    display={"flex"}
                  >
                    {v.log.log}

                    <Box ml={3}>
                      <Typography variant="h4" fontWeight={600} color={"#000"}>
                        {v.h}
                      </Typography>
                      <Typography variant="h6" lineHeight={1.5} mt={0.75}>
                        Revolutionary paradigms before enabled interfaces
                        dynamically transition technically sound paradigms with
                        cutting-edge initiatives.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
        </Box>
      </section>
      {/* footer */}
      <Footer />
    </>
  );
}
