import { Button, styled, Typography } from "@mui/material";
import React from "react";
import Development from "../components/Development";
import ClientsReview from "../components/ClientsReview";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ServicesComp from "../components/ServicesComp";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";

const MyStyledButton = styled(Button)(() => ({
  padding: "13.6px 16px",
  "& .MuiTypography-root:hover": {
    color: "#0044e3",
  },
  "&:hover": {
    background: "transparent",
  },
}));
export default function Services() {
  return (
    <>
      {/* one */}
      <section>
        <ServicesComp head={"We are Development Experts"} />
      </section>
      {/* two */}
      <section style={{ background: "#f8f9fa" }}>
        {/* textbox */}

        <Development
          button={
            <MyStyledButton disableRipple>
              <NavLink
                to={"/services/details"}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight={500}
                  sx={{
                    "&:hover": {
                      "& .mrg": {
                        // transition: "all .2s ease",
                        // paddingLeft: "4px",
                      },
                    },
                  }}
                >
                  View Details
                  <ArrowForwardOutlinedIcon
                    className="mrg"
                    sx={{
                      fontSize: "18px",
                      marginLeft: "4px",
                    }}
                  />
                </Typography>
              </NavLink>
            </MyStyledButton>
          }
        />
      </section>
      {/* three */}
      <section style={{ background: "#fff" }}>
        <ClientsReview />
      </section>
      {/* footer */}
      <Footer />
    </>
  );
}
