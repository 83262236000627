import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, IconButton, Typography, styled } from "@mui/material";
import React from "react";
const MyFooterTwo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.down("lg")]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    gap: 199,
  },
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    gap: 5,
  },
}));
export default function FooterEnd({ bg }) {
  return (
    <Box
      borderTop={"1px solid rgba(248,249,250,.05);"}
      py={3}
      sx={{ background: bg }}
    >
      <MyFooterTwo className="container">
        <Box>
          <Typography variant="subtitle1" color={"#e2e8f0"} px={1.5}>
            Stay connected with us on social media for updates, news, and more.
          </Typography>
        </Box>
        <Box>
          <IconButton
            sx={{
              "&:hover": {
                background: "#0d6efd",
                "& .clr": {
                  color: "#FFF",
                },
              },
            }}
          >
            <FacebookIcon
              className="clr"
              sx={{
                color: "#e2e8f0",
              }}
            />
          </IconButton>
          <IconButton
            sx={{
              "&:hover": {
                background: "#0d6efd",
                color: "#FFF",
              },
            }}
          >
            <InstagramIcon
              sx={{
                color: "#e2e8f0",
                "&:hover": {
                  color: "#FFF",
                },
              }}
            />
          </IconButton>
          <a
            href="https://Www.linkedin.com/company/risewithusinfo"
            target="_blank"
          >
            <IconButton
              sx={{
                "&:hover": {
                  background: "#0d6efd",
                  color: "#FFF",
                },
              }}
            >
              <LinkedInIcon
                sx={{
                  color: "#e2e8f0",
                  "&:hover": {
                    color: "#FFF",
                  },
                }}
              />
            </IconButton>
          </a>
          <IconButton
            sx={{
              "&:hover": {
                background: "#0d6efd",
                color: "#FFF",
              },
            }}
          >
            <TwitterIcon
              sx={{
                color: "#e2e8f0",
                "&:hover": {
                  color: "#FFF",
                },
              }}
            />
          </IconButton>
        </Box>
      </MyFooterTwo>
    </Box>
  );
}
