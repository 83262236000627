import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import Footer from "../components/Footer";
import MyButton from "../components/MyButton.js";
import homefirst from "../static/Home/homefirst.png";
import homethree from "../static/Home/homethree.png";
import logo from "../static/Home/logo.svg";
import { useImageLoaded } from "../utils/utils";
import "./Home.css";

const MyBox = styled(Box)(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
  },
}));

const TabDetailBox = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: 48,
  },
}));

export const Logo = ({ width, sx }) => {
  return (
    <Box width={{ xs: width, md: width + 50 }} sx={sx}>
      <Box component={"img"} src={logo} width={"100%"} height={"auto"} />
    </Box>
  );
};
export default function Home() {
  const [open, setOpen] = useState(false);
  const [ref, loading, onLoad] = useImageLoaded();
  const md = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* one */}
      <section>
        {/* <Box className="container">
          <Logo width={194} sx={{ px: 1.5, pt: 1.5 }} />
        </Box> */}
        <MyBox className="container" pt={5} pb={2.5} alignItems={"center"}>
          <Box px={1.5}>
            <Typography variant="h1" mb={0.875}>
              We know how to bring traffic to your site.
            </Typography>
            <Typography variant="h4" lineHeight={1.5}>
              {md
                ? `RiseWithUs is more than just a platform; it's a catalyst for your
                online success. In the vast digital landscape where visibility is
                paramount, we stand as your trusted partner in elevating your
                brand to new heights...`
                : `RiseWithUs is more than just a platform; it's a catalyst for your
              online success. In the vast digital landscape where visibility is
              paramount, we stand as your trusted partner in elevating your
              brand to new heights. At RiseWithUs, we understand the
              significance of guest posting as a strategic tool for enhancing
              your website's reach, driving traffic, and establishing authority
              in your niche. With our comprehensive guest posting services,
              tailored to meet your unique needs, we offer a seamless avenue for
              you to connect with your target audience and amplify your online
              presence...`}
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  handleOpen();
                }}
              >
                see more
              </a>
            </Typography>
          </Box>
          <Skeleton
            variant="rectangular"
            width="100%"
            hidden={loading}
            sx={{
              maxWidth: "620px",
              mt: { xs: 5, lg: 0 },
              borderRadius: "16px",
            }}
          >
            <div style={{ paddingTop: "92%" }} />
          </Skeleton>
          <Box
            ref={ref}
            onLoad={onLoad}
            hidden={!loading}
            pt={{ xs: 5, lg: 0 }}
            component={"img"}
            src={homefirst}
            width="100%"
            height={"100%"}
            maxHeight={"620px"}
            maxWidth={"620px"}
          />
        </MyBox>
      </section>
      {/*  tabs  */}
      <section>
        <Box bgcolor={"#0b163f"} py={{ xs: "3.75rem", md: "7.5rem" }}>
          <TabDetailBox className="container">
            <Box
              flexBasis={"50%"}
              alignSelf={"center"}
              px={1.5}
              pt={"2.25rem"}
              textAlign={"center"}
            >
              <Skeleton
                variant="rectangular"
                width="100%"
                sx={{
                  maxWidth: "494px",
                  borderRadius: "16px",
                }}
                hidden={loading}
              >
                <div style={{ paddingTop: "115.5%" }} />
              </Skeleton>
              <Box
                ref={ref}
                onLoad={onLoad}
                hidden={!loading}
                component={"img"}
                src={homethree}
                width={"100%"}
                height={"100%"}
                maxWidth={"494px"}
                maxHeight={"570px"}
              />
            </Box>
            <Box alignSelf={"center"} flexBasis={"50%"} px={1.5}>
              <Typography variant="h3" color={"#FFF"} mb={1.5}>
                We are working with {new Date().getFullYear() - 2022} years
                exprience
              </Typography>
              <Typography
                variant="h5"
                color={"#d9d9d9"}
                mb={2}
                fontWeight={400}
                lineHeight={1.5}
              >
                Ready to elevate your online presence? Join Rise With Us and
                start your journey towards greater visibility, authority, and
                growth. Whether you need guest posting or link insertion
                services, we are here to help you succeed.
              </Typography>
              <Typography variant="h6" color={"#d9d9d9"}>
                <DoneIcon
                  sx={{
                    fontSize: "20px",
                    color: "#175cff",
                    marginRight: "8px",
                  }}
                />
                Digital Conferance
              </Typography>
              <Typography variant="h6" color={"#d9d9d9"}>
                <DoneIcon
                  sx={{
                    fontSize: "20px",
                    color: "#175cff",
                    marginRight: "8px",
                  }}
                />
                Great Speak
              </Typography>
              <Typography variant="h6" color={"#d9d9d9"}>
                <DoneIcon
                  sx={{
                    fontSize: "20px",
                    color: "#175cff",
                    marginRight: "8px",
                  }}
                />
                Event Mangement
              </Typography>
            </Box>
          </TabDetailBox>
          {/* </Box> */}
        </Box>
      </section>
      <Footer />
      {/* dialog */}
      {open && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle display={"flex"} justifyContent={"center"}>
            <Logo width={150} />
          </DialogTitle>
          <DialogContent>
            <Typography variant="h3" mb={0.875}>
              We know how to bring traffic to your site.
            </Typography>
            <Typography variant="h4" lineHeight={1.5}>
              RiseWithUs is more than just a platform; it's a catalyst for your
              online success. In the vast digital landscape where visibility is
              paramount, we stand as your trusted partner in elevating your
              brand to new heights. At RiseWithUs, we understand the
              significance of guest posting as a strategic tool for enhancing
              your website's reach, driving traffic, and establishing authority
              in your niche. With our comprehensive guest posting services,
              tailored to meet your unique needs, we offer a seamless avenue for
              you to connect with your target audience and amplify your online
              presence.
              <br />
              <br />
              Our dedicated team of experts specializes in crafting compelling
              guest posts that resonate with your audience and align with your
              brand's objectives. From ideation to execution, we ensure that
              each post is meticulously curated to maximize engagement and drive
              results. Whether you're looking to build quality backlinks,
              increase website traffic, or improve your search engine rankings,
              RiseWithUs has you covered.
              <br />
              <br />
              One of the key pillars of our guest posting strategy is link
              building. We recognize the importance of high-quality backlinks in
              boosting your website's authority and credibility. Through
              strategic outreach and collaboration with authoritative websites,
              we secure valuable backlinks that contribute to your website's SEO
              efforts. Our focus on ethical link-building practices ensures
              long-term sustainability and effectiveness, delivering tangible
              results that stand the test of time.
              <br />
              <br />
              In addition to link building, our guest posting services encompass
              a wide range of off-page SEO techniques aimed at enhancing your
              website's visibility and relevance. From optimizing anchor text
              and meta descriptions to leveraging social media channels for
              promotion, we leave no stone unturned in maximizing the impact of
              your guest posts. Our holistic approach to off-page SEO ensures
              that your website remains competitive in today's dynamic digital
              landscape.
              <br />
              <br />
              At RiseWithUs, we believe in the power of collaboration and mutual
              growth. That's why we welcome guest posts from all niches and
              industries, providing a diverse platform for voices to be heard
              and ideas to be shared. Whether you're a seasoned blogger or a
              budding entrepreneur, we invite you to join our community and
              become part of a network that values creativity, authenticity, and
              innovation.
              <br />
              <br />
              In conclusion, RiseWithUs is not just a service; it's a commitment
              to your success. With our professional guest posting services,
              backed by industry expertise and a passion for excellence, we
              empower you to rise above the competition and achieve your online
              goals. Join us on this journey of growth and discovery, and
              together, let's elevate your brand to new heights!
            </Typography>
          </DialogContent>
          <DialogActions>
            <MyButton
              name={"Close"}
              variant={"contained"}
              onClick={handleClose}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
