import emailjs from "@emailjs/browser";
import {
  Box,
  Grid,
  Paper,
  styled,
  TextareaAutosize,
  TextField,
  Typography
} from "@mui/material";
import React, { useRef } from "react";
import FooterEnd from "../components/FooterEnd";
import servicesone from "../static/Services/servicesone.svg";

const CssTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    fontSize: "16px",
    "& fieldset": {
      borderColor: "rgba(0,0,0,.08)",
      borderWidth: "2px",
      borderRadius: ".375rem!important",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0,0,0,.08)",
      borderRadius: ".375rem!important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "blue",
      borderRadius: ".375rem!important",
    },
  },

  "& .MuiOutlinedInput-input": {
    "&::placeholder": {
      color: "#575757",
      opacity: 0.7,
      fontSize: "16px",
    },
  },
});
const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  // gap: 60,
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    gap: 50,
    flexDirection: "column-reverse",
  },
}));
const names = [
  {
    id: 1,
    value: "USD",
    name: "10,000-20,000",
  },
  {
    id: 2,
    value: "EUR",
    name: "20,000-30,000",
  },
  {
    id: 3,
    value: "BTC",
    name: "30,000-40,000",
  },
  {
    id: 4,
    value: "JPY",
    name: "40,000-50,000",
  },
];

export default function Contact() {
  const form = useRef();
  const [name, setname] = React.useState("");
  const [country, setCountry] = React.useState("");

  const handleChange = (event) => {
    setname(event.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_v2fut1h",
        "template_akcajls",
        form.current,
        "bFZoy5J8pbC5qZSNz"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <Box
        // img
        sx={{
          backgroundImage: `url(${servicesone}) `,
          backgroundRepeat: "no-repeat",
          backgroundColor: "#0b163f",
          backgroundPosition: "bottom ",
        }}
      >
        <StyledBox className="container" py={{ xs: "3.75rem", md: "7.5rem" }}>
          {/* text  */}
          <Box alignSelf={"center"} flexBasis={"50%"}>
            {/* text */}
            <Box>
              <Typography variant="h1" color={"#FFF"} mb={1.25}>
                Get in Touch with Rise With Us
              </Typography>

              <Typography
                variant="h4"
                color={"#d9d9d9"}
                lineHeight={1.75}
                fontStyle={"italic"}
              >
                "We are here to help you elevate your online presence through
                expert guest posting and link insertion services. If you have
                any questions, need more information, or are ready to get
                started, please reach out to us using the contact details below.
                "
              </Typography>
            </Box>
          </Box>
          {/* Contact form  */}
          <Box component={Paper} p={6} borderRadius={"1rem"} flexBasis={"40%"}>
            <Typography variant={"h3"} fontSize={"24px!important"} mb={2}>
              Please fill out the form below, and a member of our team will get
              back to you as soon as possible
            </Typography>
            <form ref={form} onSubmit={sendEmail}>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6} width={"100%"}>
                  <CssTextField
                    placeholder="name"
                    required
                    type={"text"}
                    name="user_name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CssTextField
                    placeholder="Email"
                    required
                    type={"email"}
                    name="user_email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    placeholder="Phone"
                    required
                    type={"phone"}
                    name="user_phone"
                  />
                </Grid>
              </Grid>
              {/* text area */}
              <Box
                required
                component={TextareaAutosize}
                minRows={5}
                name={"user_message"}
                aria-label="maximum height"
                placeholder="Tell us more about your project, needs and budget"
                sx={{
                  width: "100%",
                  borderColor: "rgba(0,0,0,.08)",
                  borderRadius: ".375rem",
                  borderWidth: "2px",
                  fontSize: "16px",
                  "&::placeholder": {
                    color: "#575757",
                    opacity: 0.7,
                    fontSize: "17px",
                  },
                  "&:focus ": {
                    outline: "none !important",
                    border: "2px solid blue",
                    borderRadius: ".375rem",
                  },
                  py: 1.75,
                  px: 1.75,
                }}
              />
              {/* check box */}
              <Box display={"flex"} flexDirection={"column"}>
                <Box mb={3}>
                  <label className="d-flex">
                    <input
                      required
                      type={"checkbox"}
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "5px",
                      }}
                    />
                    <Typography variant="subtitle2" ml={0.75}>
                      Yes, I'd like to receive occasional marketing emails from
                      us. I have the right to opt out at any time.
                    </Typography>
                  </label>
                </Box>
                <Box
                  component={"input"}
                  type="submit"
                  value="Get Started"
                  padding="10.4px 28px"
                  borderRadius=" 0.375rem"
                  backgroundColor="#175cff"
                  color="#FFF"
                  border={"2px solid transparent"}
                  sx={{
                    " &:hover": {
                      background: "#144ed9",
                      color: "#FFF",
                      transition: "all .3s ease",
                    },
                  }}
                />
              </Box>
            </form>
          </Box>
        </StyledBox>
      </Box>
      <FooterEnd bg={"#0b163f"} />
    </>
  );
}
