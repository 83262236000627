import InsertLinkIcon from "@mui/icons-material/InsertLink";
import PageviewIcon from "@mui/icons-material/Pageview";
import PolylineIcon from "@mui/icons-material/Polyline";
import RateReviewIcon from "@mui/icons-material/RateReview";
import StarsIcon from "@mui/icons-material/Stars";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Box, Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";
import Footer from "../components/Footer";
import aboutone from "../static/About/aboutone.jpg";
import aboutthree from "../static/About/aboutthree.jpg";
import abouttwo from "../static/About/abouttwo.jpg";
import processPic from "../static/About/processPic.jpg";
import servicesPic from "../static/About/servicesPic.jpg";
import { useImageLoaded } from "../utils/utils";
import ServicesComp from "../components/ServicesComp";
const MyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 85,
  justifyContent: "space-between",

  [theme.breakpoints.down("lg")]: {
    display: "flex",
    gap: 30,
    flexDirection: "column",
  },
}));
const MyBoxTwo = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: 85,

  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
  },
}));
const MyBoxThree = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: 85,

  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column-reverse",
  },
}));

const Story = [
  {
    num: `${new Date().getFullYear() - 2022} Years`,
    detail: "In Business",
    brdr: "1rem 0 0 0",
    clr: {
      bg: "#f8f9fa",
      num: "#ffb116",
    },
  },
  {
    num: "10+",
    brdr: "0 1rem 0 0",

    detail: "Team Members",
    clr: {
      bg: "#fff",
      num: "#175cff",
    },
  },

  {
    num: "28+",
    detail: "Clients",
    brdr: "0 0 0 1rem",

    clr: {
      bg: "#fff",
      num: "#5cc9a7",
    },
  },
  {
    num: "40+",
    detail: "Projects Completed",
    brdr: "0 0 1rem 0",

    clr: {
      bg: "#f8f9fa",
      num: "#f25767",
    },
  },
];

const joinUsArr = [
  {
    h: "Expertise",
    log: {
      log: <StarsIcon sx={{ color: "#175cff", fontSize: "36px" }} />,
    },
    detail:
      "Our team comprises professionals with a broad range of experience, ensuring we deliver top-quality services.",
  },
  {
    h: "Quality",
    log: {
      log: <VerifiedIcon sx={{ color: "#175cff", fontSize: "36px" }} />,
    },
    detail:
      "We prioritize quality over quantity, ensuring each guest post and link insertion adds real value to your brand.",
  },
  {
    h: "Transparency",
    log: {
      log: <PageviewIcon sx={{ color: "#175cff", fontSize: "36px" }} />,
    },
    detail:
      "We maintain open communication with our clients, providing regular updates and detailed reports.",
  },
  {
    h: "Results-Oriented",
    log: {
      log: <RateReviewIcon sx={{ color: "#175cff", fontSize: "36px" }} />,
    },
    detail:
      "Our strategies are designed to deliver measurable results, from increased website traffic to improved search engine rankings.",
  },
];
export default function About() {
  return (
    <>
      {/* one  */}
      <section style={{ background: "#0b163f", position: "relative" }}>
        <MyBox
          className="container"
          pt={{ xs: "3.75rem", md: "7.5rem" }}
          pb={7.5}
        >
          {/* left box */}
          <Box zIndex={44} display={"flex"} flexDirection={"column"}>
            <Box px={1.5} maxWidth={"602px"}>
              <Typography variant="h1" mb={1.25} color={"#FFF"}>
                Welcome to Rise With Us
              </Typography>
              <Typography variant="h4" lineHeight={1.5} color={"#d9d9d9"}>
                At Rise With Us, we specialize in enhancing your online presence
                through professional guest posting and link insertion services.
                Our mission is to help businesses and individuals build their
                brand, improve their SEO, and reach a wider audience through
                quality content and strategic link building.
              </Typography>
            </Box>
            <Box
              mt={6}
              component={"img"}
              src={abouttwo}
              maxWidth={{ lg: "76%", xs: "100%" }}
              borderRadius={"1rem!important"}
            />
          </Box>
          {/* right box  */}
          <Box
            sx
            display={{
              lg: "flex",
              xs: "none",
            }}
            zIndex={44}
            flexDirection={"column"}
            flexBasis={"50%"}
            gap={6}
          >
            <Box
              component={"img"}
              src={aboutone}
              width="100%"
              height={"100%"}
              borderRadius={"1rem!important"}
            />

            <Box
              component={"img"}
              src={aboutthree}
              maxWidth="126%"
              // width="100%"
              height={"100%"}
              alignSelf={"flex-end"}
              borderRadius={"1rem!important"}
            />
          </Box>
          {/* responsiveness */}
          <Box
            display={{
              xs: "flex",
              lg: "none",
            }}
            justifyContent={"center"}
            gap={2}
            zIndex={44}
          >
            <Box>
              <Box
                component={"img"}
                src={aboutone}
                width="100%"
                height={"100%"}
                borderRadius={"1rem!important"}
                maxWidth={"468px"}
              />
            </Box>
            <Box>
              <Box
                maxWidth={"468px"}
                // maxWidth={"348px"}
                component={"img"}
                src={aboutthree}
                height={"100%"}
                width="100%"
                alignSelf={"flex-end"}
                borderRadius={"1rem!important"}
              />
            </Box>
          </Box>
        </MyBox>
        {/* whitebox */}
        <Box
          bgcolor={"#fff"}
          maxHeight={{ md: "308px", xs: "262px" }}
          position={"absolute"}
          bottom={0}
          width={"100%"}
          height={"100%"}
        />
      </section>
      {/* two */}
      <section className="container">
        <MyBoxThree py={{ xs: "3.75rem", md: "7.5rem" }}>
          <Grid container flexBasis={"50%"} overflow={"hidden"}>
            {Story.map((v) => (
              <Grid item xs={6}>
                <Box
                  minHeight={"240px"}
                  border={"1px solid rgba(11,22,63,.12)"}
                  p={3}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  component={Paper}
                  elevation={0}
                  borderRadius={0}
                  sx={{
                    backgroundColor: v.clr.bg,
                    borderRadius: v.brdr,
                  }}
                >
                  {/* clients */}
                  <Box textAlign={"center"}>
                    <Typography variant="h1" color={v.clr.num}>
                      {v.num}
                    </Typography>
                    <Typography variant="h5" color={"#000"} fontWeight={600}>
                      {v.detail}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box flexBasis={"50%"} px={1.5} alignSelf={"center"}>
            {/* blue text */}
            <Typography
              variant="h4"
              color={"#175cff"}
              fontWeight={600}
              mb={0.75}
            >
              Who We Are
            </Typography>
            {/* heading */}
            {/* <Typography variant="h2" mb={1.25}>
              A Great Story Starts with a Friendly Team
            </Typography> */}
            {/* below heading */}
            <Typography variant="h6" mb={2}>
              Rise With Us is a team of dedicated professionals with diverse
              backgrounds, committed to delivering exceptional guest posting and
              link insertion services. Our experience spans various industries,
              enabling us to understand and cater to the unique needs of our
              clients. We are passionate about creating opportunities for growth
              and visibility in the digital landscape.
            </Typography>
          </Box>
        </MyBoxThree>
      </section>
      {/* three */}
      <section
        style={{
          background: "#f8f9fa",
        }}
      >
        <MyBoxTwo
          className="container"
          py={{ xs: "3.75rem", md: "7.5rem" }}
          alignItems={"center"}
        >
          {/* text box */}
          <Box
            alignSelf={"center"}
            flexBasis={"50%"}
            px={2}
            data-aos={"fade-up"}
            component={"div"}
          >
            {/* blue text */}
            <Typography
              variant="h4"
              color={"#175cff"}
              fontWeight={600}
              mb={0.75}
            >
              Our Services
            </Typography>

            <Box display={"flex"} my={4}>
              <div>
                <Box
                  component={Paper}
                  sx={{ backgroundColor: "#0d6efd" }}
                  p={2}
                >
                  <PolylineIcon sx={{ color: "#FFF" }} />
                </Box>
              </div>

              <Box ml={3}>
                <Typography
                  variant="h4"
                  color={"#0b163f"}
                  fontWeight={600}
                  mb={0.75}
                >
                  Guest Posting
                </Typography>

                <Typography variant="h6">
                  We connect you with high-authority blogs in your niche to
                  publish your content. Our team handles everything from content
                  creation to outreach and publication, ensuring your articles
                  reach the right audience and generate valuable backlinks.
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"}>
              <div>
                <Box
                  component={Paper}
                  sx={{ backgroundColor: "#f25767" }}
                  p={2}
                >
                  <InsertLinkIcon sx={{ color: "#FFF" }} />
                </Box>
              </div>

              <Box ml={3}>
                <Typography
                  variant="h4"
                  color={"#0b163f"}
                  fontWeight={600}
                  mb={0.75}
                >
                  Link Insertion
                </Typography>
                <Typography variant="h6">
                  Enhance your existing content by inserting relevant and
                  high-quality links. This service helps improve your website's
                  SEO and provides additional value to your readers by
                  connecting them to useful resources.
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* img box */}
          <Box alignSelf={"baseline"} px={2}>
            <Box
              position={"relative"}
              borderRadius={"1rem"}
              component={"img"}
              src={servicesPic}
              width="100%"
              height={"100%"}
            />
          </Box>
        </MyBoxTwo>
      </section>
      {/* four */}
      <section>
        <MyBoxTwo
          className="container"
          py={{ xs: "3.75rem", md: "7.5rem" }}
          alignItems={"center"}
          flexDirection={"row-reverse"}
        >
          {/* text box */}
          <Box
            alignSelf={"center"}
            flexBasis={"50%"}
            px={2}
            data-aos={"fade-up"}
            component={"div"}
          >
            {/* blue text */}
            <Typography
              variant="h4"
              color={"#175cff"}
              fontWeight={600}
              mb={0.75}
            >
              Our Process
            </Typography>

            <Box display={"flex"} my={4}>
              <Box>
                <Typography
                  variant="h4"
                  color={"#0b163f"}
                  fontWeight={600}
                  mb={0.75}
                >
                  Understanding Your Needs
                </Typography>

                <Typography variant="h6">
                  We start by understanding your goals, target audience, and
                  specific content requirements.
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"}>
              <Box>
                <Typography
                  variant="h4"
                  color={"#0b163f"}
                  fontWeight={600}
                  mb={0.75}
                >
                  Research and Outreach
                </Typography>
                <Typography variant="h6">
                  We identify and reach out to reputable blogs that align with
                  your niche and audience.
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"}>
              <Box>
                <Typography
                  variant="h4"
                  color={"#0b163f"}
                  fontWeight={600}
                  mb={0.75}
                >
                  Content Creation
                </Typography>
                <Typography variant="h6">
                  Our skilled writers create engaging, SEO-optimized articles
                  tailored to your brand voice. Publication and Follow-Up: We
                  ensure your content is published on chosen platforms and
                  monitor its performance to measure success.
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* img box */}
          <Box
            alignSelf={"baseline"}
            px={2}
            border={"1px solid rgba(11,22,63,.12)"}
            borderRadius={"20px"}
          >
            <Box
              position={"relative"}
              borderRadius={"1rem"}
              component={"img"}
              src={processPic}
              width="100%"
              height={"100%"}
              maxWidth={715}
            />
          </Box>
        </MyBoxTwo>
      </section>
      {/* five */}
      <section style={{ background: "#f8f9fa" }}>
        <Box py={{ xs: "3.75rem", md: "7.5rem" }}>
          <Box textAlign={"center"} mb={6.25} maxWidth={636} mx={"auto"}>
            <Typography
              variant="h4"
              color={"#175cff"}
              fontWeight={600}
              mb={0.75}
            >
              Why Choose Us
            </Typography>
            <Typography variant="h6">
              Ready to elevate your online presence? Join Rise With Us and start
              your journey towards greater visibility, authority, and growth.
              Whether you need guest posting or link insertion services, we are
              here to help you succeed.
            </Typography>
          </Box>
          <div className="container">
            <Grid container spacing={3} px={1.25}>
              {joinUsArr.map((v) => (
                <Grid item xs={12} md={6}>
                  <Box
                    minHeight={"190px"}
                    bgcolor="#fff"
                    borderRadius={"1rem"}
                    p={6}
                    display={"flex"}
                  >
                    {v.log.log}

                    <Box ml={3}>
                      <Typography variant="h4" fontWeight={600} color={"#000"}>
                        {v.h}
                      </Typography>
                      <Typography variant="h6" lineHeight={1.5} mt={0.75}>
                        {v.detail}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
        </Box>
      </section>
      <section>
        <ServicesComp
          head={"Our Vision"}
          detail={
            "At Rise With Us, we envision a digital landscape where quality content and strategic link building drive success for all. We are dedicated to helping our clients rise above the competition and achieve their online goals."
          }
        />
      </section>

      {/*six  */}

      {/* footer */}
      <Footer />
    </>
  );
}
