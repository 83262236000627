import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Position from "./Pages/Position";
import Services from "./Pages/Services";
import ServicesDetail from "./Pages/ServicesDetail";
import AOS from "aos";
import "aos/dist/aos.css";
const font = '"Poppins", sans-serif';

let theme = createTheme({
  palette: {
    primary: {
      main: "#175cff",
    },
    secondary: {
      main: "#FFF",
    },
    info: {
      main: "#175cff",
    },
  },
  typography: {
    fontFamily: font,
    htmlFontSize: 16,
    fontSize: 16,
    h1: {
      fontSize: "3rem",
      fontWeight: 800,
      color: "#0b163f",
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 700,
      color: "#0b163f",
    },
    h3: {
      fontSize: "2.125rem",
      fontWeight: 700,
      color: "#000",
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: 400,
      color: "#575757",
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 500,
      color: "##0b163f",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#575757",
    },
    subtitle1: {
      fontSize: "0.9375rem",
      fontWeight: 500,
      color: "#575757",
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 300,
      color: "#000",
    },

    button: {
      textTransform: "none",
      fontWeight: 500,
      collor: "#575757",
    },
  },
});

theme = responsiveFontSizes(theme);
function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Navbar>
          <Routes>
            <Route exact path="/" element={<Home />} />
            {/* <Route exact path="/services" element={<Services />} />
            <Route
              exact
              path="/services/details"
              element={<ServicesDetail />}
            /> */}
            <Route exact path="/about" element={<About />} />
            {/* <Route exact path="/about/position" element={<Position />} /> */}
            <Route exact path="/contact" element={<Contact />} />
          </Routes>
        </Navbar>
      </ThemeProvider>
    </>
  );
}

export default App;
