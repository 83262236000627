import React from "react";
import servicesdetailone from "../static/ServicesDetail/servicesdetailone.png";
import servicesdetailtwo from "../static/ServicesDetail/servicesdetailtwo.png";
import { Box, Grid, Paper, styled, Typography } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import WatchOutlinedIcon from "@mui/icons-material/WatchOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import Footer from "../components/Footer";
import ServicesComp from "../components/ServicesComp";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 60,
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column-reverse",
  },
}));
const StyledBoxTwo = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 60,
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
  },
}));
const projects = [
  {
    n: "50+",
    h: "Completed Projects",
    p: "Rapidiously embrace distinctive best practices after B2B syndicate.",
  },
  {
    n: "500",
    h: "Happy Client",
    p: "Energistically impact synergistic convergence for leadership..",
  },
  {
    n: "90%",
    h: "24/7 Support",
    p: "Synergistically revolutionize leadership whereas platform.",
  },
];

export default function ServicesDetail() {
  return (
    <>
      {/* service component */}
      {/* one */}
      <section>
        <ServicesComp head={"Quality Software Services"} />
      </section>
      {/* two */}
      <section className="container">
        <StyledBox py={{ xs: "3.75rem", md: "7.5rem" }}>
          {/* img */}
          <Box
            bgcolor={"#f8f9fa"}
            p={6}
            mx={1.5}
            borderRadius={"1rem"}
            flexBasis={"50%"}
          >
            <Box
              component={"img"}
              src={servicesdetailone}
              width={"100%"}
              height={"100%"}
              borderRadius={"1rem"}
            />
          </Box>
          {/* right part */}
          <Box mx={1.5} alignSelf={"center"} flexBasis={"50%"}>
            <Typography variant="h2" mb={1.25}>
              Experience your product grow business
            </Typography>
            <Typography variant="h6" lineHeight={1.5}>
              Conveniently drive stand-alone total linkage for process-centric
              content. Enthusiastically administrate robust initiatives quickly
              unleash collaborative with client-focused.
            </Typography>
            {/* icons and detail */}
            <Grid container mt={"3rem"}>
              <Grid item xs={12} sm={6}>
                <PersonOutlineIcon
                  sx={{
                    color: "#175cff",
                  }}
                  fontSize="large"
                />
                <Typography
                  variant="h4"
                  mt={3}
                  mb={0.75}
                  fontWeight={600}
                  color={"#0b163f"}
                >
                  Customer analysis
                </Typography>
                <Typography variant="h6" mb={2}>
                  Objectively exceptional via customized intellectual.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <WatchOutlinedIcon sx={{ color: "#175cff" }} fontSize="large" />
                <Typography
                  variant="h4"
                  mt={3}
                  mb={0.75}
                  fontWeight={600}
                  color={"#0b163f"}
                >
                  Real time metrics
                </Typography>
                <Typography variant="h6" mb={2}>
                  Interactively integrate extensible directed developer.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </StyledBox>
      </section>
      {/* three */}
      <section className="container">
        <StyledBoxTwo py={{ xs: "3.75rem", md: "7.5rem" }}>
          {/* left part */}
          <Box mx={1.5} alignSelf={"center"} flexBasis={"50%"}>
            <Typography variant="h2" mb={1.25}>
              Advanced analytics easy to understand for sales
            </Typography>
            <Typography variant="h6" lineHeight={1.5}>
              Conveniently drive stand-alone total linkage for process-centric
              content. Enthusiastically administrate robust initiatives quickly
              unleash collaborative with client-focused.
            </Typography>
            {/* icons and detail */}
            <Grid container mt={"3rem"}>
              <Grid item xs={12} sm={6}>
                <ForumOutlinedIcon
                  sx={{
                    color: "#175cff",
                  }}
                  fontSize="large"
                />
                <Typography
                  variant="h4"
                  mt={3}
                  mb={0.75}
                  fontWeight={600}
                  color={"#0b163f"}
                >
                  Live Chat with Agent
                </Typography>
                <Typography variant="h6" mb={2}>
                  Objectively exceptional via customized intellectual.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <HeadsetMicOutlinedIcon
                  sx={{ color: "#175cff" }}
                  fontSize="large"
                />
                <Typography
                  variant="h4"
                  mt={3}
                  mb={0.75}
                  fontWeight={600}
                  color={"#0b163f"}
                >
                  Quick Online Support
                </Typography>
                <Typography variant="h6" mb={2}>
                  Interactively integrate extensible directed developer.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {/* img */}
          <Box
            bgcolor={"#f8f9fa"}
            p={6}
            mx={1.5}
            borderRadius={"1rem"}
            flexBasis={"50%"}
          >
            <Box
              component={"img"}
              src={servicesdetailtwo}
              //   width={"540px"}
              //   height={"364px"}
              borderRadius={"1rem"}
              width={"100%"}
              height={"100%"}
            />
          </Box>
        </StyledBoxTwo>
      </section>
      {/* four clients project */}
      <section style={{ background: "#f8f9fa" }}>
        <Box className="container" py={{ xs: "3.75rem", md: "7.5rem" }}>
          {/* text */}
          <Box textAlign={"center"} px={1.5}>
            <Typography variant="h2" color={"#000"} mb={1.25}>
              Everything We Do it With Love
            </Typography>
            <Typography variant="h6" maxWidth={"636px"} mx={"auto"}>
              Progressively deploy market positioning catalysts for change and
              technically sound authoritatively e-enable resource-leveling
              infrastructures.
            </Typography>
          </Box>
          {/* projects card */}
          {/* grid box */}
          <Box>
            <Grid container spacing={3} mt={"2rem"}>
              {projects.map((v, i) => (
                <Grid item xs={12} md={6} lg={4}>
                  <Box
                    key={i}
                    component={Paper}
                    border="2px solid rgba(11,22,63,.12)"
                    borderRadius={"1rem"}
                    elevation={0}
                    p={6}
                    sx={{
                      "&:hover": {
                        border: "2px solid #175cff",
                        background: "#f8f9fa",
                      },
                    }}
                  >
                    <Typography
                      variant="h1"
                      color={"#000"}
                      fontWeight={600}
                      mb={3}
                    >
                      {v.n}
                    </Typography>
                    <Typography
                      variant="h4"
                      color={"#000"}
                      fontWeight={400}
                      mb={0.75}
                    >
                      {v.h}
                    </Typography>
                    <Typography variant="h6">{v.p}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </section>
      {/* footer */}
      <Footer />
    </>
  );
}
