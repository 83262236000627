import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  Drawer,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Logo } from "../Pages/Home";
import logo from "../static/Home/logo.svg";
import { useImageLoaded } from "../utils/utils";
import MyButton from "./MyButton";
import "./Navbar.css";
const MyStyledButton = styled(Button)(() => ({
  padding: "13.6px 16px",
  "& .MuiTypography-root:hover": {
    color: "#0044e3",
  },
  "&:hover": {
    background: "transparent",
  },
}));
const drawerWidth = 400;

export default function Navbar(props) {
  // for drawer
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // for navbar shadow changing with scrolling
  const [navbarShadow, setNavbarShadow] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 20) {
      setNavbarShadow(true);
    } else {
      setNavbarShadow(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const [ref, loading, onLoad] = useImageLoaded();
  return (
    <>
      {/* side drawer */}
      <Drawer
        disableScrollLock={true}
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            maxWidth: drawerWidth,
            width: "100%",
          },
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"} p={2} mt={2.5}>
          {/* logo */}

          <Logo width={104} sx={{ px: 1.5, pt: 1.5 }} />
          <Box mt={1.5}>
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                color: "#dc3545",
                background: "#d633841f",
              }}
            >
              {/* close icon */}
              <CloseIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </Box>
        </Box>

        <Box onClick={handleDrawerClose} px={2}>
          {/* drawer button  */}
          {/* changes */}

          <MyStyledButton disableRipple>
            <NavLink to={"/"} style={{ textDecoration: "none" }}>
              <Typography variant="subtitle1">Home</Typography>
            </NavLink>
          </MyStyledButton>
          <br />
          {/*  <MyStyledButton disableRipple>
            <NavLink to="/services" style={{ textDecoration: "none" }}>
              <Typography variant="subtitle1">Services</Typography>
            </NavLink>
          </MyStyledButton>
          <br />
          */}

          <MyStyledButton disableRipple>
            <NavLink to="/about" style={{ textDecoration: "none" }}>
              <Typography variant="subtitle1">About</Typography>
            </NavLink>
          </MyStyledButton>

          <Box display={"flex"} pl={2}>
            <NavLink to={"/contact"} style={{ textDecoration: "none" }}>
              <MyButton variant={"contained"} name={"Contact Us"} />
            </NavLink>
          </Box>
        </Box>
      </Drawer>

      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          bgcolor: "#fff",
          width: "100%",
          boxShadow: navbarShadow ? "0 10px 30px rgb(0 0 0 / 10%)" : "none",
        }}
      >
        <Box
          className="container"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {/* logo */}
          <Box py={2}>
            {/* <Typography variant="h3">Technoventive</Typography> */}
            <Link
              to={"/"}
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Logo width={104} sx={{ px: 1.5, pt: 1.5 }} />
            </Link>
          </Box>
          {/* center buttons with responsive */}
          <Box
            display={{
              lg: "block",
              xs: "none",
            }}
          >
            <MyStyledButton disableRipple>
              <NavLink to={"/"} style={{ textDecoration: "none" }}>
                <Typography variant="subtitle1" color={"#575757"}>
                  Home
                </Typography>
              </NavLink>
            </MyStyledButton>
            {/* <MyStyledButton disableRipple>
              <NavLink to={"/services"} style={{ textDecoration: "none" }}>
                <Typography
                  variant="subtitle1"
                  color={
                    NavbarBackground
                      ? navbarShadow
                        ? "#575757"
                        : "#e2e8f0"
                      : "#575757"
                  }
                >
                  Services
                </Typography>
              </NavLink>
            </MyStyledButton> */}
            <MyStyledButton disableRipple>
              <NavLink to={"/about"} style={{ textDecoration: "none" }}>
                <Typography variant="subtitle1" color={"#575757"}>
                  About
                </Typography>
              </NavLink>
            </MyStyledButton>
          </Box>
          {/* Right buttons */}
          <Box display={"flex"} alignItems={"center"}>
            <Box
              display={{
                md: "block",
                xs: "none",
              }}
            >
              <NavLink to={"/contact"} style={{ textDecoration: "none" }}>
                <MyButton variant="contained" name={"Contact Us"} />
              </NavLink>
            </Box>
            <Box
              px={2}
              display={{
                lg: "none",
                xs: "block",
              }}
            >
              {/* menu icon */}
              <IconButton
                disableRipple
                sx={{
                  "&:hover": {
                    color: "#175cff",
                  },
                }}
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <main>{props.children}</main>
    </>
  );
}
