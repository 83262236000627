import { Button } from "@mui/material";
import React from "react";

export default function MyButton({ name, variant, icon, onClick }) {
  return (
    <Button
      onClick={onClick}
      disableElevation
      disableRipple
      disableFocusRipple
      sx={{
        padding: "10.4px 28px",
        borderRadius: " 0.375rem",
        " &:hover": {
          background: "#144ed9",
          color: "#FFF",
        },
      }}
      variant={variant}
    >
      {name} {icon}
    </Button>
  );
}
