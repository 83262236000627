import { Box, Avatar, Typography } from "@mui/material";
import React from "react";
import adnan from "../static/Home/adnan.jpg";
import kazim from "../static/Home/kazim.jpg";
import haroon from "../static/Home/Harron.jpg";
import logo from "../static/Home/logo.svg";
import EmailIcon from "@mui/icons-material/Email";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
const PersonData = ({ pic, name, designation, email }) => {
  return (
    <Box>
      <Avatar
        src={pic}
        sx={{
          width: { xs: 80, md: 120 },
          height: { xs: 80, md: 120 },
          border: "1px solid rgba(30, 30, 30, 0.1)",
        }}
      />
      <Typography variant="h6" fontWeight={600} color={"#0b163f"}>
        {name}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <AccountBoxIcon fontSize="small" />
        <Typography variant="h6" fontSize={".8em"}>
          {designation}
        </Typography>
      </Box>
      {email && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <EmailIcon fontSize="small" />
          <Typography variant="h6" fontSize={".8em"}>
            {email}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default function Footer() {
  return (
    <>
      <Box py={{ xs: 2, md: 10 }} className="container">
        <Box
          display={"flex"}
          flexDirection={"column"}
          px={{ xs: 1.5, md: 0 }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
            gap: 4,
          }}
        >
          <Box width={{ xs: 150, md: 200 }}>
            <Box component={"img"} src={logo} width={"100%"} height={"auto"} />
          </Box>
          <Box>
            {/* <PersonData
              // pic={waqas}
              name={"Waqas Ali"}
              designation={"CEO"}
              // email={"waqasali534@gmail.com"}
            /> */}
          </Box>
          <Box>
            <PersonData
              pic={adnan}
              name={"Muhammad Adnan"}
              designation={"Client Success Manager"}
              email={"admin@risewithus.info"}
            />
          </Box>
          <Box>
            <PersonData
              pic={kazim}
              name={"Muhammad Kazim"}
              designation={"Outreach Manager"}
              email={"outreachmanager@risewithus.info"}
            />
          </Box>
          <Box>
            <PersonData
              pic={haroon}
              name={"Muhammad Haroon "}
              designation={"Sales manager"}
              // email={"outreachmanager@risewithus.info"}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
