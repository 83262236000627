import { Box, styled, Typography } from "@mui/material";
import React from "react";
import servicesone from "../static/Services/servicesone.svg";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "66.66666667%",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

export default function ServicesComp({ head, detail, bgcolor }) {
  return (
    <Box
      // img
      sx={{
        // backgroundImage: `url(${servicesone}) `,
        // backgroundRepeat: "no-repeat",
        backgroundColor: bgcolor,
      }}
    >
      <Box className="container">
        {/* text */}
        <StyledBox py={{ xs: "3.75rem", md: "7.5rem" }}>
          <Typography variant="h1" mb={1.25}>
            {head}
          </Typography>
          <Typography variant="h4" lineHeight={1.75}>
            {detail}
          </Typography>
        </StyledBox>
      </Box>
    </Box>
  );
}
