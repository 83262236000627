import { useEffect, useRef, useState } from "react";

export const useImageLoaded = () => {
  const [loading, setLoading] = useState(false);
  const ref = useRef("");

  const onLoad = () => {
    setLoading(true);
  };

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad();
    }
  });

  return [ref, loading, onLoad];
};
